import { t } from 'i18next';
import { padStart } from 'lodash';
import moment from 'moment';
import { ReactComponent as DocFileIcon } from '../../assets/DocFile.svg';
import { ReactComponent as JPGFileIcon } from '../../assets/JPGFile.svg';
import { ReactComponent as LinkFileIcon } from '../../assets/LinkFile.svg';
import { ReactComponent as PDFFileIcon } from '../../assets/PDFFile.svg';
import { variants } from '../../shared/constant/Colors';
import { ProviderRole } from '../../shared/constant/Common';
import { ICredit } from '../../view/screens/ClientDetails/types/client';
import { ClientMatchedOnDateFormat } from './Date';
import { getDisplayDate } from '../../../utilities/common/Date';
import { getCurrentLocale } from '../../../i18n';

export const getClientStatus = (status: string) => {
  const clientStatusColors: Record<
    string,
    { label: string; tooltipLabel: string; variant: keyof typeof variants }
  > = {
    '0': {
      label: 'inactive',
      tooltipLabel: 'Your client is inactive', // TODO confirm this with bhavya
      variant: 'gray',
    },
    '1': {
      label: 'active',
      tooltipLabel: 'ACTIVE_TOOLTIP',
      variant: 'success',
    },
    '2': {
      label: 'matched',
      tooltipLabel: 'MATCHED_TOOLTIP',
      variant: 'purple',
    },
    '3': {
      label: 'booked',
      tooltipLabel: 'BOOKED_TOOLTIP',
      variant: 'warning',
    },
    '4': {
      label: 'UNMATCHED_CLIENTS',
      tooltipLabel: 'UNMATCHED_TOOLTIP',
      variant: 'error',
    },
  };

  return clientStatusColors?.[status];
};

export const getProviderRole = (providerRole: string) => {
  const providerRoles: Record<string, { label: string }> = {
    [ProviderRole.COACHING]: {
      label: t('coachingType'),
    },
    [ProviderRole.CLINICAL]: {
      label: t('clinical'),
    },
    [ProviderRole.HELPLINE]: {
      label: t('HELPLINE'),
    },
    [ProviderRole.CARENAVIGATOR]: {
      label: t('CARE_NAVIGATOR'),
    },
    [ProviderRole.NUTRITIONIST]: {
      label: t('nutritionist'),
    },
    [ProviderRole.FITNESS]: {
      label: t('fitness'),
    },
    [ProviderRole.FINANCIAL]: {
      label: t('financial'),
    },
    [ProviderRole.EXECUTIVE]: {
      label: t('executive'),
    },
    [ProviderRole.LEGAL]: {
      label: t('legal'),
    },
    [ProviderRole.CAREOFFSITE]: {
      label: t('IN_PERSON'),
    },
    [ProviderRole.ONSITE]: {
      label: t('ONSITE'),
    },
  };

  return providerRoles?.[providerRole] ?? 'Provider';
};

export const getFileExtension = (fileName: string): string => {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex !== -1 ? fileName.substring(dotIndex + 1) : '';
};

export const getCustomAssignmentImgIcon = (fileName: string) => {
  const ext =
    fileName !== '' ? getFileExtension(fileName).toLowerCase() : 'link';

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return JPGFileIcon;
    case 'pdf':
      return PDFFileIcon;
    case 'doc':
    case 'docx':
      return DocFileIcon;
    case 'link':
      return LinkFileIcon;
    default:
      return null;
  }
};

export const getClientCreditString = (creditData: ICredit) => {
  const currentLocale = getCurrentLocale();
  let creditString = '0';
  let isPoolUncapped = false;
  if (creditData.tier === '1') {
    creditString = t('unlimited');
  } else if (creditData.tier === '0') {
    if (creditData.poolCredits > 0) {
      if (creditData.hasActiveHybridPool) {
        creditString = creditData.credit.toString();
      } else if (!creditData.credit) {
        creditString = creditData.poolCredits.toString();
        isPoolUncapped = true;
      } else if (creditData.credit > 0) {
        creditString = creditData.credit.toString();
      } else creditString = creditData.poolCredits.toString();
    } else if (!creditData.poolCredits && creditData.credit > 0) {
      creditString = creditData.credit.toString();
    }
  }

  return {
    expiresOn: getDisplayDate({
      locale: currentLocale,
      translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
      format: { ja: 'MM,DD,YYYY,dddd', en: ClientMatchedOnDateFormat },
      selectedDate: moment(creditData.expiresAt),
      translate:t,
    }),
    creditString:
      creditString === '0' ? creditString : padStart(creditString, 2, '0'),
    isPoolUncapped,
  };
};
