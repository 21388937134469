import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import moment from 'moment';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../../../shared/constant/Analytics';
import { AvailableSessionDurations } from '../../../../../shared/types/response/provider';
import { holisticRoles } from '../../../../../utilities/common/Provider';
import {
  useBookProviderSessionQuery,
  useProviderOpenSlots,
} from '../../../../../utilities/hooks/fetchHooks/provider';
import useTracking from '../../../../../utilities/hooks/useTracking';
import Typography from '../../../../../view/components/Typography';
import { Button } from '../../../components/Button';
import Modal from '../../../components/Modal';
import { IClientDetail } from '../types/client';
import useAuth from '../../../../../utilities/hooks/useAuth';
import InfoTile from '../../../components/InfoTile';
import RadioButtonGroup from '../../../components/RadioButtonGroup';
import { DatePicker } from '../../../components/DatePicker';
import SlotSelectList from './SlotSelectList';
import { ResultModal } from './ResultModal';
import CreditDetailsCard from './CreditDetailsCard/CreditDetailsCard';
import ClientDetailsContext from '../context/ClientDetailsContext';
import { getClientCreditString } from '../../../../utilities/common/Clients';

interface IBookSessionModalProps {
  open: boolean;
  handleClose: () => void;
  clientData: IClientDetail;
}

export function BookSessionModal({
  clientData,
  open,
  handleClose,
}: IBookSessionModalProps) {
  const userId = clientData?.profile?.userId;
  const providerRole = clientData?.profile?.providerRole;

  // Custom Hooks
  const { track } = useTracking();

  const { user } = useAuth();

  const { data: providerOpenSlots } = useProviderOpenSlots(
    user.id,
    providerRole,
    userId,
  );

  const { t } = useTranslation();

  const { serviceType, refreshData } = useContext(ClientDetailsContext);

  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [bookSuccessModalData, setShowBookSuccessModalData] =
    React.useState<Record<string, string> | null>(null);
  const [selectedDateInDatePicker, setSelectedDateInDatePicker] =
    React.useState<Date | undefined>();
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();
  const [selectedSessionDuration, setSelectedSessionDuration] =
    React.useState<AvailableSessionDurations>('900');
  const [selectedSlot, setSelectedSlot] = React.useState<string>('');
  const [durationList, setDurationList] = React.useState<string[]>([]);
  const [selectedDuration, setSelectedDuration] = React.useState('');
  const [isClinicalunlimited, setIsClinicalunlimited] = React.useState(false);

  //* Query to Book a session
  const providerBookDetails = {
    scheduledStartTime: selectedSlot,
    duration: selectedSessionDuration,
    role: clientData?.profile?.providerRole,
    type: '0',
    source: '5',
  };

  const handleResetStateOnSuccess = () => {
    setSelectedDuration('');
    setSelectedSessionDuration('');
    setSelectedDateInDatePicker(undefined);
    setSelectedDate(undefined);
    setSelectedSlot('');
  };

  const { mutate: bookProviderSessionFun, isLoading: isBookSessionLoading } =
    useBookProviderSessionQuery(userId, providerBookDetails, {
      onSuccess: (data) => {
        track(EventNames.providerBookSession, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.confirmSession,
          eventLabel: 'provider_confirm_session',
        });

        handleResetStateOnSuccess();
        handleClose();
        setShowBookSuccessModalData(data);
      },
      onError: (error: Error) => {
        const errorMessage = t('providerBookingErrorMessage', {
          clientName: clientData.profile.name,
        });
        toast.error(error.message || errorMessage);
        // if (setIsPopUp) {
        //   setIsPopUp(false);
        // }
      },
    });

  //* Function to getAvailable Slots
  const getAvailableSlotsDates = () => {
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[
        selectedSessionDuration as keyof typeof providerOpenSlots
      ]
    ) {
      return providerOpenSlots[
        selectedSessionDuration as keyof typeof providerOpenSlots
      ].map((slot) => slot.displayDate);
    }

    return [];
  };

  //* Function to getAvailable Slots
  const getAvailableSlots = () => {
    let slots: { label: string; value: string }[] = [];
    const selectedDisplayDate = moment(selectedDate).format('YYYY/MM/DD');
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[
        selectedSessionDuration as keyof typeof providerOpenSlots
      ] &&
      selectedDate
    ) {
      const availableSlots =
        providerOpenSlots[
          selectedSessionDuration as keyof typeof providerOpenSlots
        ];
      slots = availableSlots
        .filter((slot) => slot.displayDate === selectedDisplayDate)
        .map(({ displayTime, startTimeUtc }) => ({
          label: displayTime,
          value: startTimeUtc?.toString(),
        }));
    }

    return slots;
  };

  // Component Life Cycle
  const slots = React.useMemo(
    () => getAvailableSlots(),
    [selectedSessionDuration, selectedDate],
  );

  // Component Life Cycle
  React.useEffect(() => {
    if (providerOpenSlots) {
      const clinicalUnlimited = clientData?.credits?.tier === '1';
      setIsClinicalunlimited(clinicalUnlimited);
    }
  }, [providerOpenSlots, clientData]);

  React.useEffect(() => {
    if (providerOpenSlots) {
      if (
        (serviceType && holisticRoles.includes(serviceType)) ||
        (clientData.credits &&
          parseInt(
            getClientCreditString(clientData.credits).creditString,
            10,
          )) === 1
      ) {
        const availableDurationList = Object.keys(providerOpenSlots).filter(
          (item) => item === '1800',
        );
        setDurationList(availableDurationList);
        return;
      }
      if (isClinicalunlimited === false) {
        const availableDurationList = Object.keys(providerOpenSlots).filter(
          (item) => item !== '5400',
        );
        setDurationList(availableDurationList);
      } else {
        setDurationList(Object.keys(providerOpenSlots));
      }
    }
  }, [isClinicalunlimited, providerOpenSlots, clientData]);

  const setDurationFun = (item: string) => {
    setSelectedSessionDuration(item);
    setSelectedDuration(item);
    setSelectedDateInDatePicker(undefined);
    setSelectedDate(undefined);
    setSelectedSlot('');
  };
  // * Function to handle Select Date
  const handleSelectDate = (date?: Date) => {
    setSelectedDate(date);
    setSelectedSlot('');
    setShowDatePicker(false);
  };

  console.log('jia', bookSuccessModalData);

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        title={t('SESSION_BOOKING_HEADER')}
        className="w-[55vw]"
      >
        <CreditDetailsCard viewDetailEventLabel='session_booking' />
        <div className="mt-8">
          <div className="mb-1 font-medium">{t('DURATION')}</div>
          <InfoTile variant="gray" showIcon content={t('DURATION_SUBTEXT')} />
          <div className="mt-2">
            <RadioButtonGroup
              options={durationList.map((duration) => ({
                label: t('minutes', { count: parseInt(duration, 10) / 60 }),
                value: duration,
              }))}
              selected={selectedDuration}
              onSelect={(newSelected: string) => setDurationFun(newSelected)}
              disabled={!clientData.credits?.canBook}
            />
          </div>
          <div className="mt-6 flex justify-between">
            <div className="w-full">
              <Typography
                size={14}
                weight="500"
                color="primary"
                textAlign="left"
              >
                {t('Date')}
              </Typography>

              <Button
                variant="secondary"
                onClick={() => setShowDatePicker(true)}
                className="w-full text-start font-medium"
                disabled={!durationList.includes(selectedSessionDuration)}
              >
                {selectedDate
                  ? moment(selectedDate).format('MMM DD, YYYY - dddd')
                  : null}
              </Button>
            </div>
          </div>
          <div className="mt-6 mb-3">
            <div className="text-sm font-medium mb-2">
              {t('selectStartTime')}
            </div>
            <SlotSelectList
              options={slots}
              selectedValue={selectedSlot}
              onClick={(newSlot) => setSelectedSlot(newSlot)}
              emptyLabel={{
                show: Boolean(selectedDate) && !slots.length,
                label: 'No available slots, please try a different date.',
              }}
            />
          </div>
          <div className="w-full mt-8">
            <Button
              onClick={() => bookProviderSessionFun()}
              variant="primary"
              className="w-full"
              disabled={!selectedSlot || isBookSessionLoading}
            >
              {t('BOOK_SESSION')}
            </Button>
          </div>
        </div>

        <Modal
          handleClose={() => setShowDatePicker(false)}
          open={showDatePicker}
          title={t('SELECT_DATES_HEADER')}
        >
          <DatePicker
            selected={selectedDateInDatePicker}
            onSelect={setSelectedDateInDatePicker}
            enabledDays={getAvailableSlotsDates()}
            partiallyEnabledDays
            classNames={{
              root: 'text-sm',
              day: '[&_button]:w-10 [&_button]:h-10',
            }}
          />

          <Button
            variant="secondary"
            className="w-full text-start font-medium pointer-events-none my-4"
          >
            {selectedDateInDatePicker
              ? moment(selectedDateInDatePicker).format('MMM DD, YYYY - dddd')
              : null}
          </Button>
          <Button
            className="mt-4 w-full"
            onClick={() => handleSelectDate(selectedDateInDatePicker)}
            disabled={!selectedDateInDatePicker}
          >
            {t('SELECT_DATE_CTA')}
          </Button>
        </Modal>
      </Modal>
      <ResultModal
        open={!!bookSuccessModalData}
        handleClose={() => {
          setShowBookSuccessModalData(null);
          handleClose();
          refreshData();
        }}
        title={t('SESSION_COMFIRMATION_HEADER')}
      >
        {bookSuccessModalData ? (
          <div className="grid grid-cols-3 items-center gap-3">
            {Object.entries(bookSuccessModalData).map(([label, value]) => (
              <div
                key={label + value}
                className="px-4 col-span-1 py-2 rounded-lg bg-gray-25 border border-gray-100 text-sm"
              >
                <div className="font-semibold mb-2">{t(label)}</div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        ) : null}
      </ResultModal>
    </>
  );
}
